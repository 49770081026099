import { useEffect, useRef, useState } from 'react';
import styles from './ImplantImageComponent.module.css';
import ModalLayout from '../../general/modal/ModalLayout';

const ImplantImageComponent: React.FC<{ name: string }> = ({ name }) => {
    const imgRef = useRef(null);
    const [style, setStyle] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (imgRef.current) {
            const { width, height } = imgRef.current.getBoundingClientRect();
            if (width > height) {
                setStyle({ height: '100%' });
            } else {
                setStyle({ width: '100%' });
            }
        }
    }, []);

    const handleImageClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className={styles.container}>
            <img
                style={style}
                ref={imgRef}
                src={'https://files.nordinsight.com/implant-images/' + name}
                className={styles.image}
                alt=""
                onClick={handleImageClick}
            />
            {isModalOpen && (
                <ModalLayout
                    onClose={handleCloseModal}
                    hasCloseButton={true}
                    size="large"
                >
                    <img
                        src={
                            'https://files.nordinsight.com/implant-images/' +
                            name
                        }
                        alt=""
                        style={{ width: '50vw', height: 'auto' }}
                    />
                </ModalLayout>
            )}
        </div>
    );
};

export default ImplantImageComponent;
